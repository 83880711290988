<template>
  <div class="journey-unit" :class="{'unit-m': isMilestone, 'unit-r': isRoute}">
    <div class="milestone-unit" v-if="isMilestone">
      <div class="m-line" :class="{'line-start': isDayStart, 'line-end': isDayEnd}">
      </div>
      <div class="m-time" v-text="milestoneTime">
      </div>
      <div class="m-name" v-text="itemName">
      </div>
      <div class="m-height" v-text="milestoneHeight + ' m'"></div>
    </div>
    <div class="route-unit" v-if="isRoute">
      <div class="r-line">
      </div>
      <div class="r-distance" v-text="routeDistance + ' m'"></div>
      <div class="r-height" v-text="'(' + routeClimb + ')'"></div>
      <div class="r-timecost" v-text="routeTimeCost + ' mins'"></div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'JourneyUnit',
  data() {
    return {

    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    isDayStart: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDayEnd: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    isMilestone() {
      return this.item.type === 'milestone';
    },
    isRoute() {
      return this.item.type === 'route';
    },
    durationTime() {
      return this.item.endTimestamp - this.item.startTimestamp;
    },
    milestoneHeight() {
      if (this.isMilestone) {
        return this.item.detail.height;
      }
      return 0;
    },
    milestoneTime() {
      if (this.isMilestone) {
        if (this.isDayStart) {
          return moment(this.item.endTimestamp).format('HH:mm');
        } else {
          return moment(this.item.startTimestamp).format('HH:mm');
        }
      }
      return '';
    },
    itemName() {
      return this.item.detail.name;
    },
    routeDistance() {
      if (this.isRoute) {
        return this.item.detail.distance;
      }
      return 0;
    },
    routeTimeCost() {
      if (this.isRoute) {
        const timeDist = this.item.endTimestamp - this.item.startTimestamp;
        return (timeDist / 1000 / 60).toFixed(0);
      }
      return '';
    },
    routeClimb() {
      if (this.isRoute) {
        if (this.item.detail.heightDis < 0) {
          return `↓ ${-1 * this.item.detail.heightDis} m `;
        } else {
          return `↑ ${this.item.detail.heightDis} m `;
        }
      }
      return '';
    },
  },
}
</script>

<style lang="scss" scoped>

.journey-unit {
  --jh-grey: #ddd;
}

.milestone-unit, .route-unit {
  display: flex;
  align-items: center;
}

.milestone-unit {
  font-size: 1.2rem;
}

.route-unit {
  color: #999;
}

.milestone-unit .m-line, .route-unit .r-line {
  flex: 0 0 2rem;
  height: 3rem;
  position: relative;
  /* background-color: #f0f; */
}

.milestone-unit .m-line::before, .route-unit .r-line::before {
  position: absolute;
  content: '';
  width: 0;
  height: 100%;
  left: 50%;
  top: 0;
  border: solid 4px var(--jh-grey);
  transform: translate(-50%, 0);
}

.milestone-unit .m-line.line-start::before {
  height: 50%;
  top: 50%;
}

.milestone-unit .m-line.line-end::before {
  height: 50%;
}

.milestone-unit .m-line::after {
  position: absolute;
  content: '';
  width: 1.2rem;
  height: 1.2rem;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  background-color: var(--jh-grey);
  /* border: solid .7rem var(--jh-grey); */
  transform: translate(-50%, -50%);
}

.milestone-unit .m-time {
  flex: 0 0 auto;
  padding-left: 2rem;
}

.milestone-unit .m-name {
  flex: 0 0 auto;
  padding: 0 1rem;
}

.milestone-unit .m-height {
  flex: 0 0 auto;
}

.route-unit .r-height {
  flex: 0 0 auto;
  padding: 0 1rem;
}

.route-unit .r-distance {
  flex: 0 0 auto;
  padding-left: 2rem;
}

.route-unit .r-timecost {
  flex: 0 0 auto;
}

@media print {
  .milestone-unit .m-line::after {
    background-color: var(--jh-grey) !important;
  }
}
</style>