<template>
  <div id="book" v-if="inited">
    <div class="container">
      <h1>登山計劃書</h1>
      <div class="tools">
        <router-link :to="{name:'GroupDetail', params: {id: groupId}}" :href="'/journey/' + journey._id" class="btn tool-btn">行程頁面</router-link>
        <button class="btn tool-btn" @click="downloadPDF2">下載 PDF</button>
        <button class="btn tool-btn" @click="sharePlan">分享</button>
      </div>
      <div id="printBook">
        <div class="t-table">
          <div class="tr">
            <div class="td t-title">
              路線名稱
            </div>
            <div class="td t-content" v-text="journey.name">
            </div>
          </div>
          <div class="tr">
            <div class="td t-title">上山日期</div>
            <div class="td t-content" v-text="startTimeString">
            </div>
          </div>

          <div class="tr">
            <div class="td t-title">下山日期</div>
            <div class="td t-content" v-text="endTimeString">
            </div>
          </div>
        </div>

        <div class="t-table">
          <div class="tr">
            <div class="td t-title">
              裝備清單
            </div>
            <div class="td t-content">
              <div class="eq-row">
                <div class="eq" v-for="(item, index) in equipments" :key="index">
                  <input type="checkbox"/>
                  <p v-text="item"></p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="t-table">
          <div class="tr">
            <div class="td t-title">
              總資訊
            </div>
            <div class="td t-content">
              <span>行走｜</span>
              <span v-text="info.timeHour"></span>
              <span> h </span>
              <span v-text="info.timeMinute"></span>
              <span> min </span>
              <span> 距離｜</span>
              <span v-text="(info.sumDistance/1000).toFixed(1)"></span>
              <span>Km 爬升｜</span>
              <span v-text="info.sumClimbUp"></span>
              <span>m 下降｜</span>
              <span v-text="info.sumClimbUp"></span>
              <span>m</span>
            </div>
          </div>
          <div class="tr" v-for="(item, index) in info.dayInfo" :key="index">
            <div class="td t-title">
              第<span v-text="(index+1)"></span>天
            </div>
            <div class="td t-content">
              <span>行走｜</span>
              <span v-text="item.timeHour"></span>
              <span> h </span>
              <span v-text="item.timeMinute"></span>
              <span> min </span>
              <span> 距離｜</span>
              <span v-text="(item.sumDistance/1000).toFixed(1)"></span>
              <span>Km 爬升｜</span>
              <span v-text="item.sumClimbUp"></span>
              <span>m 下降｜</span>
              <span v-text="item.sumClimbDown"></span>
              <span>m</span>
            </div>
          </div>
        </div>

        <div class="route-map">
          <JourneyDrawer v-if="drawerDataReady" style="width: 100%" ref="rcanvas" milestone-text-color="#000" :size="1000" :show-info="false" :show-logo="false" :journey="journey" :day-index="-1" :hide-blank-milestones="drawConfig.hideBlankMilestones" :config="drawConfig.config" :title="drawingPathName"></JourneyDrawer>
        </div>

        <div class="page-before no-page-break" v-for="(dlist, index) in splitDay" :key="index">
          <h3 class="pt-4">
            <small>Day</small>
            <span v-text="`${index + 1}`"></span>
          </h3>

          <hr style="margin-top: 0; margin-bottom: 1rem;"/>

          <div class="list-row">
            <div class="list-col" v-for="(list, lIndex) in unitLineBreak(dlist)" :key="lIndex">
              <JourneyUnit :item="j.item" v-for="(j) in list" :key="j.originIndex" :is-day-start="j.originIndex === 0" :is-day-end="j.originIndex === dlist.length - 1"></JourneyUnit>
            </div>
          </div>
        </div>

        
      </div>
      
    </div>
  </div>
</template>

<script>
import JourneyDrawer from "@/components/common/JourneyDrawer.vue";
import JourneyUnit from "@/components/common/JourneyUnit.vue";
import moment from 'moment';

export default {
  name: 'GroupPlanBook',
  data() {
    return {
      readBusyName: 'READPLANBOOK',

      inited: false,
      drawingPathName: '',

      groupId: '',
      journey: {},
      path: {},

      drawConfig: {
        hideBlankMilestones: false,
        config: null,
      },
      drawerDataReady: false,

      equipments: [
        '防水外層',
        '保暖中層',
        '排汗底層',
        '綁腿',
        '護膝',
        '拖鞋',
        '備用衣物',
        '登山背包',
        '防水袋',
        '攻頂包',
        '睡袋',
        '睡墊',
        '帳篷',
        '頭燈',
        '登山杖',
        '餐具',
        '爐具',
        '打火機',
        '高山瓦斯罐',
        '個人藥品',
        '哨子',
        '行動糧食',
        '防曬用品',
        '護唇膏',
        '垃圾袋',
        '地圖資料',
        '紙本計畫書',
        '太陽眼鏡',
        '圓盤帽',
        '毛帽',
        '圍脖',
        '頭巾',
        '手套',
        '雨衣',
        '雨褲',
      ],
    };
  },
  components: {
    JourneyDrawer,
    JourneyUnit,
  },
  created() {
  },
  async mounted() {
    const groupId = this.$route.params.id;
    this.groupId = groupId;
    await this.readInfo(groupId);
    this.inited = true;
  },
  beforeCreate() {
    this.$store.commit('hideNav/SET_HIDE_NAV', true);
  },
  beforeDestroy() {
    this.$store.commit('hideNav/SET_HIDE_NAV', false);
  },
  computed: {
    startTimeString() {
      const m = moment(this.journey.journey[0].startTimestamp);
      return m.format('YYYY/MM/DD (dd) HH:mm');
    },
    endTimeString() {
      const m = moment(this.journey.journey[this.journey.journey.length - 1].endTimestamp);
      return m.format('YYYY/MM/DD (dd) HH:mm');
    },
    info() {
      let sumTime = 0;
      let sumDistance = 0;
      let sumClimbUp = 0;
      let sumClimbDown = 0;

      let lastHeight = -1;
      let dayInfo = [
        {
          sumTime: 0,
          sumDistance: 0,
          sumClimbUp: 0,
          sumClimbDown: 0,
        }
      ];
      let dayIndex = 0;
      for (let i=0;i<this.journey.journey.length;i++) {
        const item = this.journey.journey[i];
        if (item.type === 'milestone') {
          const h = item.detail.height;
          if (i !== 0) {
            const heightDistance = h - lastHeight;
            if (heightDistance > 0) {
              sumClimbUp += heightDistance;
              dayInfo[dayIndex].sumClimbUp += heightDistance;
            } else {
              sumClimbDown -= heightDistance;
              dayInfo[dayIndex].sumClimbDown -= heightDistance;
            }
          }
          lastHeight = item.detail.height;
        } else if (item.type === 'route') {
          const timeDis = (item.endTimestamp - item.startTimestamp);
          sumTime += timeDis;
          dayInfo[dayIndex].sumTime += timeDis;

          const dis = item.detail.distance;
          sumDistance += dis;
          dayInfo[dayIndex].sumDistance += dis;
        }

        if (this.journey.journeySplitIndexs.indexOf(i) !== -1) {
          dayInfo.push({
            sumTime: 0,
            sumDistance: 0,
            sumClimbUp: 0,
            sumClimbDown: 0,
          });
          dayIndex += 1;
        }
      }

      for (const d of dayInfo) {
        const m = parseInt(d.sumTime / 1000 / 60);
        d.timeHour = Math.floor(m/60);
        d.timeMinute = m%60;
      }
      const m = parseInt(sumTime / 1000 / 60);
      const timeHour = Math.floor(m/60);
      const timeMinute = m%60;

      return {
        sumTime,
        sumDistance,
        sumClimbUp,
        sumClimbDown,
        timeHour,
        timeMinute,
        dayInfo,
      };
    },
    splitDay() {
      const d = [[]];
      let index = 0;
      for (let i=0;i<this.journey.journey.length;i++) {
        const item = this.journey.journey[i];
        if (item.type === 'route') {
          item.detail.heightDis = this.journey.journey[i + 1].detail.height - this.journey.journey[i - 1].detail.height;
        }
        d[index].push(this.journey.journey[i]);
        if (this.journey.journeySplitIndexs.indexOf(i) !== -1) {
          d.push([]);
          index += 1;
          d[index].push(this.journey.journey[i]);
        }
      }
      return d;
    },
  },
  methods: {
    async readInfo(groupId) {
      await this.$store.dispatch('appendComponentBusy', this.readInfoBusyName);
      try {
        const groupData = await this.$store.dispatch('api/getGroupDetailPromise', groupId);
        const journeyData = await this.$store.dispatch('api/getJourneyDetailPromise', groupData.journeys[0]);
        this.journey = Object.assign({}, journeyData);
        const pathSerial = this.journey.baseOnPathSerial;
        const pathData = await this.$store.dispatch('api/getPathOriginPromise', pathSerial);
        this.path = Object.assign({}, pathData);
        this.drawConfig.hideBlankMilestones = pathData.tags.indexOf('百岳') !== -1;
        const drawConfig = await this.$store.dispatch('api/getPathDrawingConfigPromise', pathSerial);
        if (drawConfig !== null) {
          this.drawConfig.config = Object.assign({}, drawConfig);
        }
        this.drawerDataReady = true;

      } catch (error) {
        await this.$store.dispatch('appendErrorMsg', error.toString());
      } finally {
        await this.$store.dispatch('clearComponentBusy', this.readInfoBusyName);
      }
    },
    unitLineBreak(units) {
      // const maxLen = 20;
      const maxLen = 999;
      const res = [[]];
      let index = 0;
      for (let i=0;i<units.length;i++) {
        if (i > 0 && i%maxLen === 0) {
          res.push([]);
          index++;
        }
        res[index].push({
          originIndex: i,
          item: units[i]
        });
      }
      return res;
    },
    async sharePlan() {
      try {
        const shareData = {
          title: `${this.journey.name} 登山計畫書 | Joyhike揪山`,
          text: "製作並下載屬於自己的登山計畫書",
          url: `${window.location.href}`,
        };
        await window.navigator.share(shareData);
        window.trackBtnClicked('panlBookShare');

      } catch (err) {
        console.error(err);
      }
    },
    downloadPDF2() {
      window.print();
      // window.trackBtnClicked('planBookPrintPDF');
    },
  }
}
</script>

<style>
html, body {
  background-color: #fff !important;
}
</style>

<style lang="scss" scoped>
html, body {
  background-color: #fff;
  --jh-grey: #ddd;
}

h1 {
  color: var(--text-85, rgba(0, 0, 0, 0.85));
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
}

.tools {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 2rem;
  margin-bottom: 1.5rem;
}

.tools .tool-btn {
  background-color: #000;
  border-radius: 999px;
  color: #fff;
  display: block;
  padding: .5rem 1.5rem;
  width: 200px;
}

.t-table {
  border: none;
  width: 100%;
  margin-bottom: 1rem;
}

div.tr {
  display: flex;
  width: 100%;
}



div.td {
  padding: .75rem 1rem;
  border: solid 1px #000;
}

div.td.t-title {
  flex: 0 0 6rem;
  white-space: nowrap;
  text-align: center;
  background: var(--Black-200, #F5F5F5);
  font-weight: 800;
}

div.td.t-content {
  flex: 1 1;
}



.eq-row {
  display: flex;
  column-gap: 1rem;
  flex-wrap: wrap;
}

.eq-row .eq {
  flex: 0 0 auto;
  margin-bottom: .5rem;
}

.eq-row input {
  appearance: auto;
  -webkit-appearance: auto;
  display: inline-block;
}

.eq-row p {
  margin: 0;
  display: inline-block;
}

.route-img {
  height: 500px;
}

.page-before {
  break-before: page;
}

.route-map {
  position: relative;
  ::v-deep {
    .route-canvas {
      max-width: 60%;
      margin-left: auto;
      margin-right: auto;
      canvas {
        max-width: 100%;
      }
    }
  }
}


.list-row {
  display: flex;
  flex-wrap: wrap;
}

.list-row .list-col {
  flex: 0 0 100%;
  /* flex: 0 0 50%; */
  padding: 1rem;
  margin-bottom: 2rem;
  overflow-x: auto;
}

.no-page-break {
  page-break-inside: avoid;
}

@media (max-width: 500px) {

  .tools {
    /* flex-direction: column; */
    row-gap: .5rem;
    column-gap: .5rem;
  }

  .tools .tool-btn {
    /* width: 100%; */
    width: 6rem;
    padding: .5rem 0;

  }

  .route-map .route-canvas {
    max-width: 100%;
  }

  div.tr {
    flex-wrap: wrap;
  }

  div.td.t-content {
    flex: 1 0 50%;
  }
}

@page {
  size: A4 portrait;
  margin: 1cm;
}

@media print {
  * {
    -webkit-print-color-adjust: exact !important;
  }

  .route-map .route-canvas {
    max-width: 60%;
  }

  .tools {
    display: none;
  }
}
</style>